<template>
  <BasePage :isLoaded="true">
    <template v-slot:content>
      <v-container class="py-16">
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <div class="policy">
              <h2>PRIVACY POLICY</h2>
              <p>Here below you may find the information on our policies regarding the collection, use and disclosure of personal data gathered after visiting the website <a target="_blank" href="http://www.regi.it">regi.it</a></p>
              <p>Reference regulations:</p>
              <ul class="link_list">
                <li>
                  <b>Privacy Authority</b>
                  <ul>
                    <li>Identification of simplified methods for the informative and the acquisition of consent for the use of cookies – May, 8th 2014 [3118884]</li>
                    <li>Guidelines on the personal data process for the online profiling – March, 19th 2015</li>
                    <li>Informative and cookies consent</li>
                  </ul>
                </li>
                <li>
                  <a href="http://edps.europa.eu"><b>European Data Protection Supervisor</b></a>
                  <ul>
                    <li><a href="https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies#section_2">Eu legislation on cookies</a></li>
                    <li><a href="https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=CELEX:32002L0058:EN:HTML">ePrivacy Directive</a></li>
                    <li><a href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX:32009L0136">Directive 2009/136/EC - Repository</a></li>
                    <li><a href="http://ec.europa.eu/justice/data-protection/article-29/documentation/opinion-recommendation/files/2013/wp208_en.pdf">Working Document 02/2013 providing guidance on obtaining consent for cookies</a></li>
                  </ul>
                </li>
              </ul>
              <br>
              <p><strong>Regi srl</strong> gives maximal importance to the visitors’ privacy. The following statement refers to regi.it website and no other sites that may be reached through links and it is intended also as <strong>Informative</strong> visible to the visitors pursuant to article 13 D. Lgs 196/2003 "Codice in materia di protezione dei dati personali" and of the European Legislation on cookies policy (see reference above).</p>
              <h3>Data controller</h3>
              <p>Visitors must be informed that following the consultation of this website, processing of personal data will be possible. The data controller is Regi srl - Italy - via Enrico Mattei, 6-10-14 - 26010 Bagnolo Cremasco (CR) - T. +39 0373 31861 - info@regi.it - Privacy.</p>
              <h3>Data processing place</h3>
              <p>The place where processing of data connected to web services is conducted is the headquarter of Regi Srl and data are processed only by technical staff in charge of this job. Data can also be processed from other staff from the before mentioned company for necessities related to the website maintenance. The staff is nominated Data Controller pursuant to the article 29 of the Code of personal data protection, at the headquarters of said companies.</p>
              <h3>Processed data</h3>
              <h4>Navigation data</h4>
              <p>For the exclusive purpose of IT security and monitoring of performances, the systems subtended the website regi.it can acquire information potentially referable to personal data of the user.</p>
              <p>For example, the IP address of the computer may be registered, as well as the time of access to the website, the pages visited, the addresses in URI (Uniform Resource Identifier) notation for resources requested and other parameters related to the operating system and to the computing environment of the person who navigates the website.</p>
              <p>These data are registered in dedicated logs and can be communicated only to the judicial authority, only in case of significative events of IT security. Otherwise, in absence of IT security problems, the data are deleted from the systems as fast as possible and no longer than one working week.</p>
              <h4>Cookie</h4>
              <p><strong><em>Definitions</em></strong></p>
              <p>Cookies are small strings of text that the websites visited from the user send to its terminal (usually the browser), where the are stored and re-transmitted to the same websites the following visit of the same visitor. During the navigation of a website, the user can receive on his own terminal also cookies that are sent from different websites or different web servers (“third-parties), on which it is possible to find some elements (images, maps, sounds, specific links to pages from other domains) present on the website that the user is visiting.</p>
              <p>Cookies, usually present on the users’ browser with a high number and sometimes with characteristics of a large temporal persistence, are used for different goals: execution of IT authentications, monitoring of sessions, memorization of specific information regarding the users that access the server, ecc…</p>
              <p>In order to properly conform to the Privacy norms, the Privacy Authority has identified two classes of cookies:</p>
              <p style="margin: 30px;"><strong>a. Technical cookies</strong></p>
              <p>Technical cookies are used with the only goal of “transmitting a communication on an electronic net of communication or in the to the extent strictly necessary to the supplier of the service of the company to explicitly request of the user to give this service (cfr. art. 122, comma 1, from the Codice).</p>
              <p>These are not used for any other goal and are normally installed directly by the owner or the manager of the website. They can be divided into navigation cookies or session cookies, that guarantee the normal navigation and use of the website (allowing, for example, to purchase or authenticate to access personal areas); cookies analytics, similar to technical cookies when they are used directly from the website manager to gather information on the number of visitors and the behavior on the website; functionality cookies that allow the user to navigate according to a series of selected criteria (language, selection of products to be purchased) with the goal of making the service of said website.</p>
              <p style="margin: 30px;"><strong>b. Profiling cookies</strong></p>
              <p>Profiling cookies have to goal to create profiles related to the user and are used with the goal of send ad messages in line with the preferences manifested in the same area of navigation online. Due to them being particularly invasive in the private area of the user, the European and Italian normative requires that the user must be informed on the use of these cookies and to express his consensus (source: Privacy Authority)</p>
              <h4>Browser settings related to cookies</h4>
              <p>The user has the faculty to give consent to the installation of cookies. Some functionalities – sometimes fundamental – of this website also may not be available if one or more cookies as disabled. The settings related to this choice can be verified and modified in the preferences dialog of the browser. Here below are the links to the most common browser producers, where it is possible to receive information on how to modify the cookies’ settings.</p>
              <ul class="link_list">
                <li><a target="_blank" href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Mozilla</a></li>
                <li><a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a></li>
                <li><a target="_blank" href="https://support.apple.com/it-it/HT201265">Safari</a></li>
                <li><a target="_blank" href="http://windows.microsoft.com/it-it/windows7/block-enable-or-allow-cookies">Internet Explorer</a></li>
                <li><a target="_blank" href="http://help.opera.com/Windows/10.00/it/cookies.html">Opera</a></li>
              </ul>
              <br>
              <p>Or visiting the website:<a target="_blank" href="http://youronlinechoices.eu/">youronlinechoices.eu</a></p>
              <h3>Technical cookies</h3>
              <p>The website <a target="_blank" href="http://www.regi.it">regi.it</a> uses the following technical cookies, strictly limited to the transmission of session’s indicators (made of numbers casually generated by the server), necessary to consent a safe and efficient navigation on the website:</p>
              <div class="table">
                <table>
                  <thead>
                    <tr>
                      <th><strong><em>Nome</em></strong></th>
                      <th><strong><em>Dominio</em></strong></th>
                      <th><strong><em>Categoria</em></strong></th>
                      <th><strong><em>Finalità</em></strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p>SESSIONID</p></td>
                      <td><p>regi.it</p></td>
                      <td><p>cookies tecnici</p></td>
                      <td><p>Ottimizzare navigazione</p></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br>
              <h4>Data retention</h4>
              <p>Technical cookies are deleted when the navigation browser is closed.</p>
              <h3>Profiling cookies</h3>
              <p>In order to offer a better navigation experience, the website <a target="_blank" href="http://www.regi.it">regi.it</a> uses media services from third parties, managed and regulated by cookies.</p>
              <p>These cookies, besides providing these functionalities, can identify the user’s behaviors and for this reason fall under the “profiling” cookies category, as defined from the Data Authority.</p>
              <p>I these cookies should not be accepted, the navigation on <a target="_blank" href="http://www.regi.it">regi.it</a> website would still be accessible but with some limitations.</p>
              <p>The option of sharing contents through social networks (such as Facebook, Twitter, YouTube) is disabled.</p>
              <p>Here is the list of cookies and related privacy policy:</p>
              <div class="table">
                <table>
                  <thead>
                    <tr>
                      <th><strong><em>Name</em></strong></th>
                      <th><strong><em>Domain&gt;</em></strong></th>
                      <th><strong><em>Category</em></strong></th>
                      <th><strong><em>Finality</em></strong></th>
                      <th><strong><em>Policy Privacy</em></strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p>__atuvc</p></td>
                      <td><p>regi.it</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>__atuvs</p></td>
                      <td><p>regi.it</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>bt</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>di2</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>dt</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>loc</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>uid</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>uit</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>um</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>uvc</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                    <tr>
                      <td><p>B</p></td>
                      <td><p>addThis.com</p></td>
                      <td><p>AddThis</p></td>
                      <td><p>Recording of the preferences of the user in the sharing of the website contents on social channels.</p></td>
                      <td><p><a target="_blank" href="http://www.addthis.com/privacy/privacy-policy">http://www.addthis.com/privacy/privacy-policy</a></p></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br>
              <h4>Data retention</h4>
              <p>The cookies’ storage conditions are stated on the related websites of third parties.</p>
              <h3>Navigation statistics</h3>
              <p>On <a target="_blank" href="http://www.regi.it">regi.it</a> website it is activated a statistical survey using <a target="_blank" href="http://www.google.it/analytics/learn/privacy.html">Google Analytics</a>, which uses cookies and other analysis parameters according to the conditions stated into the document related Google Privacy Policy and on the specific document regarding the use of the information gathered using <a target="_blank" href="http://www.google.it/analytics/learn/privacy.html">Google Analytics</a>.</p>
              <p>Cookies from <a target="_blank" href="http://www.google.it/analytics/learn/privacy.html">Google Analytics</a> are directly used by the data controller of the website <a target="_blank" href="http://www.regi.it">regi.it</a> with the purpose of optimizing the information regarding number of users visiting the website and the behavior of them. For this reason they are included into the category of technical cookies, as specified by the Data Authority.</p>
              <div class="table">
                <table>
                  <thead>
                    <tr>
                      <th><strong>Name</strong></th>
                      <th><strong>Domain</strong></th>
                      <th><strong>Category</strong></th>
                      <th><strong>Finality</strong></th>
                      <th><strong>Policy Privacy</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p>__utma</p></td>
                      <td><p>.regi.it</p></td>
                      <td><p>Google Analytics</p></td>
                      <td><p>Navigation statistics</p></td>
                      <td><p><a target="_blank" href="https://www.google.com/analytics/learn/privacy.html?hl=it">https://www.google.com/analytics/learn/privacy.html?hl=it</a></p></td>
                    </tr>
                    <tr>
                      <td><p>__utmb</p></td>
                      <td><p>.regi.it</p></td>
                      <td><p>Google Analytics</p></td>
                      <td><p>Navigation statistics</p></td>
                      <td><p><a target="_blank" href="https://www.google.com/analytics/learn/privacy.html?hl=it">https://www.google.com/analytics/learn/privacy.html?hl=it</a></p></td>
                    </tr>
                    <tr>
                      <td><p>__utmc</p></td>
                      <td><p>.regi.it</p></td>
                      <td><p>Google Analytics</p></td>
                      <td><p>Navigation statistics</p></td>
                      <td><p><a target="_blank" href="https://www.google.com/analytics/learn/privacy.html?hl=it">https://www.google.com/analytics/learn/privacy.html?hl=it</a></p></td>
                    </tr>
                    <tr>
                      <td><p>__utmt_UA-15177699-5</p></td>
                      <td><p>.regi.it</p></td>
                      <td><p>Google Analytics</p></td>
                      <td><p>Navigation statistics</p></td>
                      <td><p><a target="_blank" href="https://www.google.com/analytics/learn/privacy.html?hl=it">https://www.google.com/analytics/learn/privacy.html?hl=it</a></p></td>
                    </tr>
                    <tr>
                      <td><p>__utmv</p></td>
                      <td><p>.regi.it</p></td>
                      <td><p>Google Analytics</p></td>
                      <td><p>Navigation statistics</p></td>
                      <td><p><a target="_blank" href="https://www.google.com/analytics/learn/privacy.html?hl=it">https://www.google.com/analytics/learn/privacy.html?hl=it</a></p></td>
                    </tr>
                    <tr>
                      <td><p>__utmz</p></td>
                      <td><p>.regi.it</p></td>
                      <td><p>Google Analytics</p></td>
                      <td><p>Navigation statistics</p></td>
                      <td><p><a target="_blank" href="https://www.google.com/analytics/learn/privacy.html?hl=it">https://www.google.com/analytics/learn/privacy.html?hl=it</a></p></td>
                    </tr>
                    <tr>
                      <td><p>ga</p></td>
                      <td><p>.regi.it</p></td>
                      <td><p>Google Analytics</p></td>
                      <td><p>Navigation statistics</p></td>
                      <td><p><a target="_blank" href="https://www.google.com/analytics/learn/privacy.html?hl=it">https://www.google.com/analytics/learn/privacy.html?hl=it</a></p></td>
                    </tr>
                    <tr>
                      <td><p>gat</p></td>
                      <td><p>.regi.it</p></td>
                      <td><p>Google Analytics</p></td>
                      <td><p>Navigation statistics</p></td>
                      <td><p><a target="_blank" href="https://www.google.com/analytics/learn/privacy.html?hl=it">https://www.google.com/analytics/learn/privacy.html?hl=it</a></p></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br>
              <h4>Data retention</h4>
              <p>The statistical analyses are completely anonymous and not traceable to the single user. Therefore, they are stored until reaching the company purpose and then deleted, usually within the working week.</p>
              <p><em>Registered visitor</em></p>
              <p>The website <a target="_blank" href="http://www.regi.it">regi.it</a> manages the areas reserved to registered visitors and offers a newsletter service only for registered users.</p>
              <h3>Rights of data subjects</h3>
              <p>Visitors of the website, in capacity of data subjects, can benefit of the rights guaranteed by the art. 7 del D. Lgs 196/2003 and can obtain in any moment the confirmation of the existence or not of said data, can know the content and the origin and can verify the accuracy or ask for integration or update or correction.</p>
              <p>To the senses of the same article, they have the right to ask for the cancellation, the transformation to anonymity or the block of the processed data for violation of the law, as well as to oppose to them being processed in any moment, for legitimate reasons.</p>
              <p>The requests must be sent to the data controller at the headquarter or sent them to the email address: <a href="mailto:info@regi.it">info@regi.it</a></p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '@/organisms/BasePage'
export default {
  name: 'PrivacyPage',
  components: {
    BasePage
  },
  metaInfo () {
    return {
      title: 'Privacy Policy',
      meta: [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'Privacy Policy' },
        { vmid: 'description', name: 'description', content: 'Di seguito sono descritte le modalità di trattamento dei dati personali effettuate sui dati raccolti a seguito della consultazione del sito' },
        { vmid: 'ogdescription', property: 'og:description', content: 'Di seguito sono descritte le modalità di trattamento dei dati personali effettuate sui dati raccolti a seguito della consultazione del sito' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  min-width: rem(500px);
  overflow-x: auto;

  table {
    width: 100%;
  }

  th {
    text-align: left;
  }

  th,
  td {
    padding: rem(8px);
  }
}

a {
  color: $color-fucsia;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.5em;
}
</style>
